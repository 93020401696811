<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 font-weight-bold">
        {{ "Tasks Logs" }}
      </div>
    </div>
    <v-tabs v-model="tab" centered icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#clients"
        >Not Clients <v-icon>mdi-account-alert</v-icon></v-tab
      >
      <v-tab href="#delincuents"
        >Delinquents <v-icon>mdi-account</v-icon></v-tab
      >
      <v-tab href="#history">History<v-icon>mdi-history</v-icon></v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="clients">
        <v-card flat> <clients-logs /> </v-card
      ></v-tab-item>
      <v-tab-item value="delincuents">
        <v-card flat> <delincuent-logs /> </v-card
      ></v-tab-item>
      <v-tab-item value="history"
        ><v-card flat> <history-logs /> </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import ClientsLogs from "./ClientsLogs.vue";
import DelincuentLogs from "./DelincuentLogs.vue";
import HistoryLogs from "./HistoryLogs.vue";
export default {
  name: "logs-tasks",
  components: { ClientsLogs, HistoryLogs, DelincuentLogs },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style lang=""></style>
